import React, { useEffect, useState} from 'react'

import { Link } from 'gatsby'

import '../../styles/hamburgers.min.css'
import {navHolder} from './Nav.module.scss'

import {AnimatePresence, motion} from 'framer-motion'

export default function MainNav({isSmallScreen, isScrolled}) {
  const [navOpen, setNavOpen] = useState(false)

  useEffect(()=>{
    setNavOpen(!isSmallScreen)
  },[isSmallScreen, setNavOpen])

  const variants = {
    pre: {
      opacity: 0
    },
    visible: {
      opacity:1
    },
    post: {
      opacity:0
    }
  }


  return (
    <div className={navHolder}>
        { isSmallScreen &&
        <div className="burger">
          <button
            className={`hamburger hamburger--slider
            ${navOpen ? 'is-active' : ''}`}
            type="button"
            onClick={()=>setNavOpen(!navOpen)}
            >
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
        }

        <AnimatePresence>
        {
        (!isSmallScreen || navOpen) && 
          <motion.ul 
            variants={variants}
            initial="pre"
            animate="visible"
            exit="post"
            key="linksHolder"
            className={`linksHolder ${isSmallScreen && 'smallScreenNavHolder'}`}
            onClick={()=>setNavOpen(false)}
            role="button"
            onKeyDown={(e)=> {
              if (e.code==='Escape')
              setNavOpen(false)
            }}
            >
              <li><Link to="/" title="Back to the Homepage" activeClassName="active">Home</Link></li>
              <li><Link to="/about" title="About" activeClassName="active">About</Link></li>
              <li><Link to="/our-team" title="Our Team" activeClassName="active">Our Team</Link></li>
              <li><Link to="/case-studies" title="Case Studies" activeClassName="active">Case Studies</Link></li>
              {/* <li><Link to="/members-area" title="Members' Area" activeClassName="active">Members' Area</Link></li> */}
              <li><Link to="/contact" title="Contact" activeClassName="active">Contact</Link></li>
          </motion.ul>
        }
        </AnimatePresence>
    </div>
  )
}
