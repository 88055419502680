import React, {useState, useEffect}from "react"

import useWindowSize from '../../hooks/useWindowSize'

import "../../styles/normalize.css"
import "../../styles/styles.scss"
import { mainHolder } from './Layout.module.scss'

import Header from "./Header"
import Footer from "./Footer"
import FooterContact from "./FooterContact"

import useScrollY from '../../hooks/useScrollY'
import { AnimatePresence, motion } from "framer-motion"

import {isBrowser} from '../../utils/helperFunctions'


const mainVariant = {
  i: {
    opacity:0,
    y:'3rem'
  },
  v: {
    opacity:1,
    y:0,
    transition: {
      type:"tween",
      duration:0.5
    }
  },
  o: {
    opacity:0,
    y:'-3rem',
    transition: {
      type: "tween",
      duration:0.3
    }
  }
}

const Layout = ({ children, location, ...props}) => {
  const [isHome, setIsHome] = useState(children?.props?.path==='/')
  const [isContact, setIsContact] = useState(true)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const size = useWindowSize()
  const scrollY = useScrollY()
  const [isScrolled, setIsScrolled] = useState((scrollY > 60))


  //set smallscreen
  useEffect(()=>{
    const breakpoint = 700

    if(size.width < breakpoint)
    setIsSmallScreen(true)

    if(size.width > breakpoint)
    setIsSmallScreen(false)

    if(((size.width / size.height) > 1.7) && (size.height < 600))
    setIsSmallScreen(true)

  }, [size, setIsSmallScreen])


  //set scrolled
  useEffect(()=>{
    setIsScrolled((scrollY > 60))
  }, [scrollY, setIsScrolled])


  //set ishome
  useEffect(()=>{
    setIsHome(children?.props?.path==='/')
    setIsContact(children?.props?.uri === '/contact')
  }, [children])

  const updateScroll = ()=>{
    if(isBrowser()) {
      window.scrollTo(0,0)
    }
  }


  return (
    <div className={mainHolder}>

      <Header isHome={isHome} isScrolled={isScrolled} isSmallScreen={isSmallScreen} />
      <AnimatePresence exitBeforeEnter onExitComplete={()=>updateScroll()}>
        <motion.main key={children?.props?.path} variants={mainVariant} initial="i" animate="v" exit="o">
          {children}
        </motion.main>
      </AnimatePresence>

        {  !isContact ? 
        <Footer /> :
        <FooterContact />  }

    </div>
  )
}

export default Layout
