import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { footerHolder } from './Footer.module.scss';
import FooterLogo from '../../images/svgs/FooterLogo.svg';

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <div className="left">
        <div className="innerSection">
          <FooterLogo />
          <p>
            Pygmalion Capital Advisers LLP is an English limited liability partnership registered with UK Companies House (OC419445) and authorised
            and regulated by the FCA (FRN 816191)
          </p>
          <ul className="legalLinks">
            <li>© {new Date().getFullYear()} Pygmalion Capital</li>
            <li>
              <Link to="/privacy" title="Privacy Notice">
                Privacy Notice
              </Link>
            </li>
            <li>
              <Link to="/sfdr" title="SFDR Disclosures">
                SFDR Disclosures
              </Link>
            </li>
            <li>
              <Link to="/contact" title="Contact Pygmalion Capital">
                Contact Pygmalion Capital
              </Link>
            </li>
          </ul>
        </div>
        <StaticImage
          src="../../images/footer.jpg"
          alt="Pygmalion Capital LTD - Dedicated to Hotel Investing"
          quality={80}
          width={1500}
          className="backgroundImage"
        />
      </div>
      <div className="right">
        <div className="innerSect">
          <h3>Interested in learning more?</h3>
          <p>
            If you’d like more information about Pygmalion’s investment activities, please contact{' '}
            <a href="mailto:info@pygmalioncapital.com" title="Contact Pygmalion Capitol">
              info@pygmalioncapital.com
            </a>
          </p>
          <Link to="contact" title="Request and information pack" className="button">
            Request an information pack
          </Link>
          <Link to="contact" title="Contact Pygmalion" className="button">
            Get in touch
          </Link>

          <div className="addresses">
            <div className="address">
              <p>
                Pygmalion Capital Advisers LLP
                <br />
                50 Sloane Avenue,
                <br />
                London
                <br />
                SW3 3DD
                <br />
                United Kingdom
              </p>

              <p>
                <a href="tel:00442075091796" title="Call Pygmalion Capital (UK branch)">
                  +44 (0)20 7509 1796
                </a>
              </p>

              <p>
                <a href="mailto:info@pygmalioncapital.com" title="Email Pygmalion Capital">
                  info@pygmalioncapital.com
                </a>
              </p>
            </div>
            <div className="address">
              <p>
                Pygmalion Capital Advisers Iberia, S.L
                <br />
                Avinguda Diagonal 640
                <br />
                6th floor, Office 666
                <br />
                08017 Barcelona
                <br />
                Spain
              </p>

              <p>
                <a href="tel:00442075091796" title="Call Pygmalion Capital (Spain branch)">
                  +34 93 545 4175
                </a>
              </p>

              <p>
                <a href="mailto:info@pygmalioncapital.com" title="Email Pygmalion Capital">
                  info@pygmalioncapital.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
