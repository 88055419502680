import React from 'react'
import { Link } from 'gatsby'
import {contactFooterHolder} from './FooterContact.module.scss'
import FooterLogo from '../../images/svgs/FooterLogo.svg'

export default function FooterContact() {
  return (
    <div className={contactFooterHolder}>
      <div className="inner">
        <FooterLogo />
        <p>Pygmalion Capital Advisers LLP, an English limited liability partnership registered with UK Companies House (OC419445) and authorised and regulated by the FCA (FRN 816191)</p>
        <ul className="legalLinks">
          <li>© {new Date().getFullYear()} Pygmalion Capital</li>
          <li><Link to="/privacy" title="Privacy Notice">Privacy Notice</Link></li>
          <li><Link to="/pillar3" title="Pillar 3 Disclosures">Pillar 3 Disclosures</Link></li>
          <li><Link to="/contact" title="Contact Pygmalion Capital">Contact Pygmalion Capital</Link></li>
        </ul>
      </div>
    </div>
  )
}