import React from 'react'
import Nav from './Nav'
import { headerHolder } from './Layout.module.scss'
import { Link } from 'gatsby'

import LogoOhm from '../../images/svgs/LogoOhm.svg'

export default function Header({isHome, isScrolled, isSmallScreen}) {
  return (
    <div className={`${headerHolder} ${isScrolled ? 'headerScrolled' : ''}`}>
      <h1>Pygmalion Capital</h1>

      <Link to="/" title="Pygmalion Capital Home Page"><LogoOhm /></Link>
      <Nav isSmallScreen={isSmallScreen} isScrolled={isScrolled} />
    </div>
  )
}
